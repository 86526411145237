export default {
  apiKey: "AIzaSyDHcrVxSLoGA-_t-Ad46Ds1YEvbM2GazQw",
  authDomain: "chip-player-js.firebaseapp.com",
  databaseURL: "https://chip-player-js.firebaseio.com",
  projectId: "chip-player-js",
  storageBucket: "chip-player-js.appspot.com",
  messagingSenderId: "762111649253"
};
// export default {
//   apiKey: "AIzaSyC10SfeD-T87myobCvT63o7Dj_zf0nW1tI",
//   authDomain: "chip-player-js-dev.firebaseapp.com",
//   projectId: "chip-player-js-dev",
//   storageBucket: "chip-player-js-dev.appspot.com",
//   messagingSenderId: "176220739684",
//   appId: "1:176220739684:web:a2037f48e7744d03802771"
// };
