export const GM_INSTRUMENTS = [
  'Piano         ',
  'Bright Piano  ',
  'Electric Piano',
  'Honky-tonk    ',
  'Rhodes Piano  ',
  'FM Piano      ',
  'Harpsichord   ',
  'Clavinet      ',
  'Celesta       ',
  'Glockenspiel  ',
  'Music Box     ',
  'Vibraphone    ',
  'Marimba       ',
  'Xylophone     ',
  'Tubular Bells ',
  'Dulcimer      ',
  'Drawbar Organ ',
  'Perc Organ    ',
  'Rock Organ    ',
  'Church Organ  ',
  'Reed Organ    ',
  'Accordion     ',
  'Harmonica     ',
  'Accordion     ',
  'Steel Guitar  ',
  'Nylon Guitar  ',
  'Jazz Guitar   ',
  'Clean Guitar  ',
  'Muted Guitar  ',
  'Overdrive Guit',
  'Distort Guitar',
  'Guit Harmonics',
  'Acoustic Bass ',
  'Finger Bass   ',
  'Picked Bass   ',
  'Fretless Bass ',
  'Slap Bass 1   ',
  'Slap Bass 2   ',
  'Synth Bass 1  ',
  'Synth Bass 2  ',
  'Violin        ',
  'Viola         ',
  'Cello         ',
  'Contrabass    ',
  'Trem Strings  ',
  'Pizz Strings  ',
  'Harp          ',
  'Timpani       ',
  'String Ens 1  ',
  'String Ens 2  ',
  'Syn Strings 1 ',
  'Syn Strings 2 ',
  'Choir Aahs    ',
  'Voice Oohs    ',
  'Synth Voice   ',
  'Orch Hit      ',
  'Trumpet       ',
  'Trombone      ',
  'Tuba          ',
  'Muted Trumpet ',
  'French Horn   ',
  'Brass Section ',
  'SynthBrass 1  ',
  'SynthBrass 2  ',
  'Soprano Sax   ',
  'Alto Sax      ',
  'Tenor Sax     ',
  'Baritone Sax  ',
  'Oboe          ',
  'English Horn  ',
  'Bassoon       ',
  'Clarinet      ',
  'Piccolo       ',
  'Flute         ',
  'Recorder      ',
  'Pan Flute     ',
  'Blown Bottle  ',
  'Shakuhachi    ',
  'Whistle       ',
  'Ocarina       ',
  'Lead: Square  ',
  'Lead: Sawtooth',
  'Lead: Calliope',
  'Lead: Chiff   ',
  'Lead: Charang ',
  'Lead: Voice   ',
  'Lead: Fifths  ',
  'Lead: Bass    ',
  'Pad: New Age  ',
  'Pad: Warm     ',
  'Pad: Polysynth',
  'Pad: Choir    ',
  'Pad: Bowed    ',
  'Pad: Metallic ',
  'Pad: Halo     ',
  'Pad: Sweep    ',
  'FX: Rain      ',
  'FX: Soundtrack',
  'FX: Crystal   ',
  'FX: Atmosphere',
  'FX: Brightness',
  'FX: Goblins   ',
  'FX: Echoes    ',
  'FX: Sci-fi    ',
  'Sitar         ',
  'Banjo         ',
  'Shamisen      ',
  'Koto          ',
  'Kalimba       ',
  'Bag pipe      ',
  'Fiddle        ',
  'Shanai        ',
  'Tinkle Bell   ',
  'Agogo         ',
  'Steel Drums   ',
  'Woodblock     ',
  'Taiko Drum    ',
  'Melodic Tom   ',
  'Synth Drum    ',
  'Reverse Cymbal',
  'Fret Noise    ',
  'Breath Noise  ',
  'Seashore      ',
  'Bird Tweet    ',
  'Telephone Ring',
  'Helicopter    ',
  'Applause      ',
  'Gunshot       ',
];

export const GM_DRUM_KITS = {
  0:  'Std Drum Kit  ',
  8:  'Room Drum Kit ',
  16: 'Power Drum Kit',
  24: 'Elec Drum Kit ',
  25: 'TR808 Drum Kit',
  32: 'Jazz Drum Kit ',
  40: 'Brush Drum Kit',
};
